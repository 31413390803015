import axios from 'axios';
import _ from 'lodash';
// import notification from '../../../../notification/notify'

export default {
  // created() {
  //   EventBus.$on('POPOVER_OPENED', (componentName) => {
  //     if (componentName !== this.field.name) {
  //       this.show = false
  //     }
  //   })
  // },
  methods: {
    getUsersByProjectId(id) {
      if (id) {
        axios.get(`get_users_by_project/${id}`).then((response) => {
          this.dictionaries.users = response.data.data;
        }).then(() => {
          if (!_.find(this.dictionaries.users, ['id', this.data.user_id])) {
            this.data.user_id = null;
          }
        });
      } else {
        this.dictionaries.users = {};
        this.data.user_id = null;
      }
    },

  },
};
